.Projects {
  padding-bottom: 3rem;
  height: auto;
}

.Project {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin-top: 1.5rem;
}

.project-name {
  margin: 0.75rem 0 0.25rem;
}

.project-caption {
  margin: 0 0 1rem;
}

.project-thumbnail {
  width: 100%;
}

.project-thumbnail img {
  vertical-align: bottom;
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
  transition: box-shadow ease-in-out 0.25s;
}

.project-thumbnail img:hover {
  box-shadow: 0.25px 0.25px 15px var(--clr-primary);
}

.project-description {
  font-size: 0.9rem;
}

/* TECHNOLOGY */

.technologies-title {
  margin: 1.25rem 0 0.5rem;
}

.technologies-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5px;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgb(187, 187, 187);
}

.Technology {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5px;
  padding: 0.25rem 0.75rem;
  min-height: 2rem;
  min-width: 5rem;
  font-size: 0.8rem;
  background-color: var(--clr-light);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Project {
    flex-direction: row;
    margin: 1.5rem auto;
    border-bottom: none;
  }

  .project-dates {
    padding: 1rem 2rem 0 0;
    margin: 0 2rem -1.5rem 0;
    font-size: 0.9rem;
    min-width: 200px;
    text-align: right;
    border-right: 1px solid rgb(187, 187, 187);
  }

  .project-name:before {
    content: " \2713 ";
    position: absolute;
    display: block;
    margin: -0.25rem 0 0 -2.5rem;
    color: var(--clr-secondary);
  }

  .Technology {
    padding: 0.5rem 1rem;
  }
}
