.Contact {
  height: calc(100vh - 6.5rem);
}

.social-container {
  margin: 1.5rem auto 2.5rem;
  max-width: 900px;
}

.SocialRow {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  max-width: 469px;
}

.social-label {
  font-family: var(--ff-secondary);
  letter-spacing: 2px;
  font-weight: bold;
}

.social-link {
  color: black;
  text-decoration: none;
}

.social-description {
  margin-left: 0.5rem;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
}

.social-description:after {
  display: block;
  content: "";
  border-bottom: solid 1px black;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.social-description:hover:after {
  transform: scaleX(1);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Contact {
    height: calc(100vh - 2rem);
  }

  .social-container {
    padding: 0 2rem;
  }
}
