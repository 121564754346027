.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 3.5rem;
  background-color: var(--clr-light-95);
}

/* SOCIALS */

.Socials {
  display: flex;
  justify-content: center;
}

.Social {
  padding: 0 0.5rem;
  font-size: 1.1rem;
  color: black;
  transition: color 0.3s;
}

.Social:hover,
.Social:focus {
  color: var(--clr-primary);
}

/* HAMBURGER */

.Hamburger {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 5rem;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.hamburger-icon {
  display: block;
  position: relative;
}

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  background: black;
  width: 2em;
  height: 3px;
  border-radius: 1em;
  transition: transform 250ms ease-in-out;
}

.hamburger-icon::before,
.hamburger-icon::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
}

.hamburger-icon::before {
  top: 6px;
}

.hamburger-icon::after {
  bottom: 6px;
}

/* NAVLINKS */

.NavLinks {
  margin: 0;
  padding: 0.25rem 0;
  border-top: 1px solid white;
  background-color: var(--clr-light-95);
}

.NavLink {
  padding: 0 1.25rem;
  height: 50px;
  list-style: none;
}

.NavLink a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  color: black;
  letter-spacing: 2px;
  height: 100%;
  width: 100%;
}

.NavLink a:hover,
.NavLink a:focus {
  -webkit-text-stroke-width: 0.75px;
}

.show-navlinks {
  display: block;
}

.hide-navlinks {
  display: none;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Navbar {
    display: none;
  }
}
