@font-face {
  font-family: "Lora";
  src: local("Lora"),
    url(./fonts/Lora/Lora-VariableFont_wght.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: local("Lora"),
    url(./fonts/Lora/Lora-Italic-VariableFont_wght.ttf) format("truetype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./fonts/Raleway/Raleway-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./fonts/Raleway/Raleway-Bold.ttf) format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./fonts/Raleway/Raleway-Italic.ttf) format("truetype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./fonts/Raleway/Raleway-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

:root {
  --clr-light: #f2e5d5;
  --clr-light-95: rgba(242, 229, 213, 0.95);
  --clr-light-80: rgba(242, 229, 213, 0.8);
  --clr-bright: #a0ccf2;
  --clr-dark: #6b90bf;
  --clr-primary: #3c6aa6;
  --clr-secondary: #70731d;

  --ff-primary: Lora, serif;
  --ff-secondary: Raleway, sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--ff-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: var(--ff-secondary);
}

.section-container {
  padding: 2rem 7.5% 0;
  background: rgb(251, 251, 251);
}

.section-title,
.section-description {
  max-width: 900px;
}

.section-title {
  margin: 0;
  padding-bottom: 0.5rem;
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: var(--clr-secondary);
  border-bottom: 2px solid black;
}

.section-description {
  margin: 1.5rem auto 3rem;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .section-container {
    padding: 2rem 10% 0;
  }

  .section-title {
    margin: 0 auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .section-container {
    margin-left: 300px;
    padding-left: 50px;
    width: 100%;
  }

  .section-description {
    padding: 0 2rem;
  }
}
