.App {
  display: flex;
  flex-direction: column;
  background-image: url("./img/backup/vineyards.jpeg"),
    url("./img/vineyards.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .App {
    flex-direction: row;
  }
}
