.Home {
  display: flex;
  flex-direction: column;
  padding: 6.75rem 0 0 2rem;
  max-width: 80%;
  height: calc(100vh - 11.25rem);
  transition: all 0.5s;
}

.home-title,
.home-subtitle {
  margin: 0;
  color: white;
  text-shadow: 2px 2px 2px black;
}

.home-title {
  margin-top: 0.5rem;
  font-size: 2.5rem;
}

.home-subtitle {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

/* BUTTONS */

.home-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 0;
  width: 150px;
  min-height: 2.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  letter-spacing: 2px;
  text-decoration: none;
  text-shadow: 0.5px 0.5px black;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

.home-button:hover,
.home-button:focus {
  border: 2px solid white;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .Home {
    padding: 13rem 5rem 0;
    height: calc(100vh - 17.5rem);
  }

  .home-subtitle {
    margin-bottom: 6rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .Home {
    padding: 8rem 5rem 0;
    margin-left: 300px;
    height: calc(100vh - 8rem);
  }

  .home-title {
    font-size: 3rem;
  }

  .home-subtitle {
    margin-bottom: 5rem;
    font-size: 1.75rem;
    line-height: 2.75rem;
    letter-spacing: 2px;
  }

  .home-button {
    margin-bottom: 2.5rem;
    width: 200px;
    height: 3rem;
    font-size: 1.25rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .Home {
    padding-top: 9rem;
    height: calc(100vh - 9rem);
  }

  .home-subtitle {
    margin-bottom: 6rem;
  }
}
