.about-subtitle {
  margin-bottom: 0.5rem;
}

.about-description {
  margin: 0 0 0.75rem 0;
}

.about-description a {
  color: black;
  transition: color 0.25s ease-in-out;
}

.about-description a:hover {
  color: var(--clr-primary);
}

.Bullet {
  display: flex;
  padding: 0.25rem 0;
}

.bullet-icon {
  width: 2rem !important;
  margin-right: 0.5rem;
}

.bullet-description {
  margin: 0;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .About {
    height: calc(100vh - 2rem);
    overflow: auto;
  }
}
